import React, { SyntheticEvent } from "react";
import {
	IconButton,
	IconButtonProps,
	Tooltip,
	useToast,
} from "@chakra-ui/react";
import { BsShareFill } from "react-icons/bs";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";

interface ShareButtonProps {
	buttonProps?: Omit<IconButtonProps, "aria-label">;
	link?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ buttonProps, link }) => {
	const toast = useToast();

	const copyToClipboard = (ev: SyntheticEvent) => {
		ev.stopPropagation();
		if (link) {
			navigator.clipboard.writeText(link);
			toast({
				title: "Copied to clipboard!",
				status: "success",
				duration: 4000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			<Tooltip label={"Click to copy url"}>
				{!!link && (
					<IconButton
						variant="transparent"
						onClick={copyToClipboard}
						aria-label="open menu"
						right={0}
						p={0}
						icon={<BsShareFill color={brandColor[0]} size={"20px"} />}
						{...buttonProps}
					/>
				)}
			</Tooltip>
		</>
	);
};
export default ShareButton;
