import {
	ICityOption,
	IOption,
} from "@components/form/form-async-select/form-async-select";

export interface IMapToLocationsParams {
	cities: ICityOption[];
	countries: IOption[];
}
export const mapToLocations = (params: IMapToLocationsParams) => {
	const { cities, countries } = params;
	console.log(cities);
	const locations: string[] = [];

	cities.forEach((city) => {
		const cityValue = city.value.toString().includes("_empty_")
			? "_empty_"
			: city.value;
		locations.push(`${city.data?.country},${cityValue}`);
	});

	countries.forEach(
		(country) =>
			!locations.some((location) =>
				location.includes(country.value.toString()),
			) && locations.push(`${country.value}`),
	);

	return locations;
};
