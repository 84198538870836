import dynamic from "next/dynamic";
import { FC, memo } from "react";
import { MapProps } from "@components/map/map";

const DynamicMap: FC<MapProps> = (props) => {
	const Map = dynamic(() => import("./map"), { ssr: false });
	return <Map {...props} />;
};

export default memo(DynamicMap);
