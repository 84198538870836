import React, { useCallback } from "react";
import {
	Heading,
	Link,
	Stack,
	StackDirection,
	StackProps,
	Text,
} from "@chakra-ui/react";

interface FormInputProps extends StackProps {
	label?: string;
	link?: string;
	text?: string | null;
	direction?: StackDirection;
	onClick?: () => void;
	showPlaceholder?: boolean;
}

const FormInputDisplay: React.FC<FormInputProps> = ({
	label,
	link,
	text,
	direction,
	onClick,
	showPlaceholder = true,
	...rest
}) => {
	const DataComponent = useCallback(
		(props) => (
			<Heading
				p={0}
				m={0}
				size={"m"}
				opacity={text ? 1 : 0.5}
				fontWeight={text ? "unset" : 400}
				{...props}
			/>
		),
		[text],
	);
	return (
		<Stack
			direction={direction || "column"}
			mb={3}
			cursor={onClick ? "pointer" : "default"}
			onClick={onClick}
			{...rest}
		>
			{!!label && (
				<Text p={0} m={0} opacity={0.7} fontWeight={300}>
					{label}
				</Text>
			)}
			{link ? (
				<DataComponent
					as={Link}
					href={link}
					dangerouslySetInnerHTML={{ __html: text || "Not provided" }}
				/>
			) : (
				<DataComponent
					dangerouslySetInnerHTML={{ __html: text || "Not provided" }}
				/>
			)}
		</Stack>
	);
};

export default FormInputDisplay;
