import React, { FC, useMemo, useState } from "react";
import {
	Center,
	Flex,
	Heading,
	IconButton,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Table as ChakraTable,
	Select,
	Tbody,
	Td,
	Th,
	Thead,
	Tooltip,
	Tr,
	Text,
	useColorModeValue,
	TableRowProps,
	HStack,
	Box,
} from "@chakra-ui/react";
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	TriangleDownIcon,
	TriangleUpIcon,
} from "@chakra-ui/icons";
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	PaginationState,
	Row,
	SortingState,
	TableOptions,
	useReactTable,
} from "@tanstack/react-table";
import { ListResponse, Obj } from "@interfaces/types";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";

interface TableProps<DataType>
	extends Partial<
		Omit<
			TableOptions<DataType>,
			"data" | "onSortingChange" | "getCoreRowModel" | "getSortedRowModel"
		>
	> {
	data: ListResponse<DataType>;
	columns: ColumnDef<DataType, any>[];
	paginationState?: PaginationState;
	sortingState?: SortingState;
	onPageChange?: (data: PaginationState) => void;
	onSortingChange?: (sorting: SortingState) => void;
	onRowClick?: (row: Row<DataType>, itemData: DataType) => void;
	labels?: {
		emptyListLabel?: string;
	};
	rowProps?: TableRowProps;
	headersShown?: boolean;
}

function Table<DataType>({
	data,
	columns,
	paginationState,
	sortingState,
	onPageChange,
	onRowClick,
	labels,
	onSortingChange,
	headersShown = true,
	rowProps,
	...rest
}: TableProps<DataType>) {
	const { items, total } = data;
	const [sorting, setSorting] = React.useState<SortingState>(
		sortingState || [],
	);
	const [inputedIndex, setInputedIndex] = useState(0);
	const [{ pageIndex, pageSize }, setPagination] =
		React.useState<PaginationState>(
			paginationState || {
				pageIndex: 0,
				pageSize: 100,
			},
		);

	const pagination = React.useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);

	const pageCount = useMemo(() => {
		return Math.ceil(total / pageSize);
	}, [pageSize, total]);

	const table = useReactTable({
		data: items,
		columns,
		pageCount,
		state: {
			pagination,
			sorting,
		},
		onSortingChange: (data) => {
			onSortingChange && onSortingChange(data as SortingState);
			return setSorting(data);
		},
		onPaginationChange: (data) => {
			onPageChange && onPageChange(data as PaginationState);
			return setPagination(data);
		},
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		manualPagination: true,
		// getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
		debugTable: true,
		...rest,
	});

	const { emptyListLabel } = labels || {};

	if (!items?.length) {
		return (
			<Center w={"100%"}>
				<Heading size={"md"} fontWeight={600}>
					{emptyListLabel || "This list is empty :("}
				</Heading>
			</Center>
		);
	}

	const shadowColor = useColorModeValue(brandColor[0], brandColor[1]);

	return (
		<>
			<ChakraTable
				style={{ borderCollapse: "separate", borderSpacing: "0 0.5em" }}
			>
				<Thead display={!headersShown ? "none" : undefined}>
					{table.getHeaderGroups().map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Th key={header.id} colSpan={header.colSpan} px={0} pr={4}>
									{header.isPlaceholder ? null : (
										<div
											{...{
												style: {
													display: "inline-flex",
													flexDirection: "row",
													alignItems: "center",
													width: "100%",
													cursor: header.column.getCanSort()
														? "pointer"
														: "unset",
												},
												onClick: header.column.getToggleSortingHandler(),
											}}
										>
											{flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
											{{
												desc: (
													<Box w={"30px"}>
														<TriangleDownIcon aria-label="sorted descending" />
													</Box>
												),
												asc: (
													<Box w={"30px"}>
														<TriangleUpIcon aria-label="sorted ascending" />
													</Box>
												),
											}[header.column.getIsSorted() as string] ?? null}
										</div>
									)}
								</Th>
							))}
						</Tr>
					))}
				</Thead>
				<Tbody>
					{table.getRowModel().rows.map((row) => {
						return (
							<Tr
								key={row.id}
								onClick={() => onRowClick && onRowClick(row, row.original)}
								style={{
									cursor: onRowClick ? "pointer" : "unset",
									borderBottomColor: shadowColor,
								}}
								p={0}
								_hover={{
									boxShadow: onRowClick
										? `0px 4px 8px ${shadowColor}70`
										: "none",
									transition: "box-shadow 0.2s ease-in-out",
								}}
								{...rowProps}
							>
								{row.getVisibleCells().map((cell) => {
									return (
										<Td key={cell.id} p={0} pr={4}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</Td>
									);
								})}
							</Tr>
						);
					})}
				</Tbody>
			</ChakraTable>
			{!!paginationState && (
				<Flex justifyContent="space-between" m={4} alignItems="center">
					<Flex>
						<Tooltip label="First Page">
							<IconButton
								aria-label={"First page"}
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
								icon={<ArrowLeftIcon h={3} w={3} />}
								mr={4}
							/>
						</Tooltip>
						<Tooltip label="Previous Page">
							<IconButton
								aria-label={"Previous Page"}
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
								icon={<ChevronLeftIcon h={6} w={6} />}
							/>
						</Tooltip>
					</Flex>

					<Flex align="center" justify={"center"}>
						<Text flexShrink="0" mr={8} mb={0}>
							Page{" "}
							<Text fontWeight="bold" as="span">
								{table.getState().pagination.pageIndex + 1} of{" "}
							</Text>
							<Text fontWeight="bold" as="span">
								{table.getPageCount()}
							</Text>
						</Text>
						<Text flexShrink="0" mb={0}>
							Go to page:{" "}
						</Text>
						<NumberInput
							ml={2}
							mr={8}
							w={28}
							min={1}
							max={table.getPageCount()}
							onChange={(value) => {
								const page = value ? Number(value) - 1 : 0;
								setInputedIndex(page);
							}}
							onKeyDown={(e) => {
								if (e.code === "Enter") {
									table.setPageIndex(inputedIndex);
								}
							}}
							defaultValue={table.getState().pagination.pageIndex + 1}
						>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>
						<Select
							w={32}
							value={table.getState().pagination.pageSize}
							onChange={(e) => {
								table.setPageSize(Number(e.target.value));
							}}
						>
							{[20, 50, 100, 300].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</Select>
					</Flex>

					<Flex>
						<Tooltip label="Next Page">
							<IconButton
								aria-label={"Next Page"}
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
								icon={<ChevronRightIcon h={6} w={6} />}
							/>
						</Tooltip>
						<Tooltip label="Last Page">
							<IconButton
								aria-label={"Last Page"}
								onClick={() => table.setPageIndex(table.getPageCount() - 1)}
								disabled={!table.getCanNextPage()}
								icon={<ArrowRightIcon h={3} w={3} />}
								ml={4}
							/>
						</Tooltip>
					</Flex>
				</Flex>
			)}
		</>
	);
}

export default Table;
