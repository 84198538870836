import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	jobOfferLocationsQK,
	jobOfferQK,
	jobOffersQK,
	jobRequestQK,
	jobRequestsQK,
} from "@definitions/react-query.keys";
import {
	getJobOffer,
	getJobOfferLocations,
	getJobOffers,
} from "@api/job-offers.api";
import { IJobOffer, IJobOfferFilters } from "@interfaces/api/job-offers.types";
import Sheet from "@components/layout/sheet/sheet";
import DynamicMap from "@components/map/map.dynamic";
import {
	Box,
	Center,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	Heading,
	HStack,
	IconButton,
	Stack,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { Button } from "@components";
import { useRouter } from "next/router";
import colors, {
	brandColor,
} from "@definitions/chakra/theme/foundations/colors";
import { LatLngExpression } from "leaflet";
import ApplyForJobButton from "@components/buttons/apply-for-job-button/apply-for-job-button";
import { readFromStorage, writeToStorage } from "@utils/local-storage";
import {
	LSFiltersCandidateJobOffers,
	LSPaginationCandidateJobOffers,
	LSSortingCandidateJobOffers,
	LSTemporaryToken,
} from "@definitions/local-storage.keys";
import { UserTypeEnum } from "@interfaces/api/auth.types";
import { createJobRequest } from "@api/job-requests.api";
import { IApiError } from "@interfaces/api/api.types";
import useAuthContext from "@hooks/useAuthContext";
import JobOfferFormDisplayForCandidates from "@components/forms-data-display/job-offer-form-display/job-offer-form-display-for-candidates";
import FDC from "@components/layout/fetch-dependable-content/FDC";
import Table from "@components/table/table";
import {
	ColumnDef,
	PaginationState,
	SortingState,
} from "@tanstack/react-table";
import ShareButton from "@components/buttons/share-button/share-button";
import { emptyListResponse } from "@definitions/api";
import useWindowSize from "@hooks/useWindowSize";
import JobOffersFiltersForCandidates from "@components/filters/job-offers-filters-for-candidates";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@chakra-ui/icons";
import { MainButton } from "@components/buttons/main-button/main-button";

const JobOffersForCandidates: FC = () => {
	const router = useRouter();
	const toast = useToast();
	const queryClient = useQueryClient();
	const { authData, updateAuthData, updateAuthToken } = useAuthContext();

	const { id } = router.query;
	const jobOfferId = Number(id);

	const listRef = useRef() as React.MutableRefObject<HTMLInputElement>;

	const [mapCenter, setMapCenter] = useState<LatLngExpression>([
		48.8543, 2.3465,
	]);
	const [filters, setFilters] = useState<IJobOfferFilters>(
		readFromStorage(LSFiltersCandidateJobOffers) || {},
	);
	const [pagination, setPagination] = useState<PaginationState>(
		readFromStorage(LSPaginationCandidateJobOffers) || {
			pageIndex: 0,
			pageSize: 100,
		},
	);
	const [sorting, setSorting] = useState<SortingState>([]);

	const {
		isOpen: isMapShown,
		onOpen,
		onClose,
	} = useDisclosure({ defaultIsOpen: true });
	const {
		isOpen: isMapImageShown,
		onOpen: openMapImage,
		onClose: closeMapImage,
	} = useDisclosure({ defaultIsOpen: true });

	const {
		data = emptyListResponse,
		isLoading,
		isError,
		dataUpdatedAt,
		error = {},
		refetch,
	} = useQuery({
		queryKey: jobOffersQK({ pagination, sorting, filters }),
		queryFn: () => getJobOffers({ pagination, sorting, filters }),
		refetchOnWindowFocus: false,
	});

	const { data: currentJobOffer } = useQuery({
		queryKey: jobOfferQK([{ id: jobOfferId }]),
		queryFn: () => getJobOffer(jobOfferId),
		refetchOnWindowFocus: false,
		enabled: !!id,
	});

	const { data: markers = emptyListResponse } = useQuery({
		queryKey: jobOfferLocationsQK(),
		queryFn: () => getJobOfferLocations(),
		refetchOnWindowFocus: false,
	});

	const { error: errorMessage } = (error as IApiError)?.data || { data: {} };

	useEffect(() => {
		writeToStorage(LSPaginationCandidateJobOffers, pagination);
		writeToStorage(LSSortingCandidateJobOffers, sorting);
	}, [pagination, sorting]);

	const onFiltersSubmit = (filters: IJobOfferFilters) => {
		//reset pagination due to different number of items after filtering is changed
		writeToStorage(LSPaginationCandidateJobOffers, {
			pageIndex: 0,
			pageSize: 100,
		});
		setPagination({
			pageIndex: 0,
			pageSize: 100,
		});
		setFilters(filters);
	};

	const onJobRequestSuccess = async () => {
		toast({
			title: "Great!",
			description: "You have successfully applied for this job offer!",
			status: "success",
			duration: 4000,
			isClosable: true,
		});
		await queryClient.invalidateQueries(jobRequestsQK());
		await refetch();
		const tempToken = readFromStorage(LSTemporaryToken);
		if (tempToken) {
			writeToStorage(LSTemporaryToken, null);
			await updateAuthToken(null);
			await updateAuthData(null);
		}
		await router.push("/");
	};

	const onUpdateError = (error: IApiError) => {
		toast({
			title: error?.data?.codeString || "Request failed",
			description: error?.data?.error || "Something went wrong",
			status: "error",
			duration: 4000,
			isClosable: true,
		});
	};

	const createJobRequestQuery = useMutation(createJobRequest, {
		mutationKey: jobRequestQK([{ userId: authData?.id, jobOfferId }]),
		onSuccess: onJobRequestSuccess,
		onError: onUpdateError,
	});

	const onApplyClick = async (data?: any & { id: number }) => {
		const { id } = data || authData || {};
		await createJobRequestQuery.mutateAsync({
			userId: id,
			jobOfferId,
		});
	};

	const mappedMarkers = useMemo(() => {
		return markers
			? markers?.items?.map((marker) => ({
					...marker.location,
					jobOffer: marker,
			  }))
			: undefined;
	}, [dataUpdatedAt]);

	const selectedMarker = useMemo(() => {
		if (currentJobOffer) {
			setMapCenter({
				lat: currentJobOffer?.location?.lat as number,
				lng: currentJobOffer?.location?.lng as number,
			});
			return {
				...(currentJobOffer?.location || {}),
				jobOffer: currentJobOffer,
			};
		}
	}, [currentJobOffer]);

	const canApply = useMemo(() => {
		console.log(currentJobOffer);
		return (
			!authData ||
			(authData?.role.value === UserTypeEnum.Candidate &&
				!currentJobOffer?.alreadyApplied)
		);
	}, [id]);

	const jobOfferIndex = useMemo(() => {
		return data.items.findIndex((item) => item.id === currentJobOffer?.id);
	}, [currentJobOffer]);

	const onJobOfferClick = (id?: number) => {
		id && router.push(`${router.pathname}?id=${id}`);
	};

	const onJobOfferPreviewClose = () => {
		router.replace(router.pathname);
	};

	const previewNext = () => {
		if (typeof jobOfferIndex === "number") {
			const nextIndex = jobOfferIndex + 1;
			const nextJobOffer = data?.items[nextIndex];
			console.log(nextIndex, nextJobOffer.id);
			onJobOfferClick(nextJobOffer?.id);
		}
	};

	const previewPrevious = () => {
		if (jobOfferIndex) {
			const prevIndex = jobOfferIndex - 1;
			const prevJobOffer = data?.items[prevIndex];
			console.log(prevIndex, prevJobOffer.id);
			onJobOfferClick(prevJobOffer?.id);
		}
	};

	const toggleMap = () => {
		isMapShown ? onClose() : onOpen();
		setTimeout(() => (isMapShown ? closeMapImage() : openMapImage()), 200);
	};

	const textColor = useColorModeValue("brand.700", "brand.400");

	const columns: ColumnDef<IJobOffer, any>[] = useMemo(() => {
		const headers: ColumnDef<IJobOffer, any>[] = [
			{
				accessorKey: "title",
				header: "Job title",
				cell: ({ row, getValue }) => (
					<VStack
						align={"start"}
						spacing={{ base: 2, lg: 8 }}
						m={0}
						minW={"280px"}
						maxW={{ base: "310px", lg: "370px" }}
						px={4}
						py={{ base: 4, lg: 8 }}
					>
						<Heading
							fontSize={{ base: "18px", lg: "24px" }}
							fontWeight={600}
							color={textColor}
							w={"100%"}
							wordBreak={"break-word"}
							m={0}
						>
							{getValue()}
						</Heading>
						<VStack
							align={"start"}
							display={{ base: "flex", lg: "none" }}
							spacing={2}
							w={"100%"}
						>
							<Text fontWeight={400} color={"grey"} mb={0}>
								{row.original.location?.city}
							</Text>
							<Text fontWeight={400} color={"grey"} mb={0}>
								{row.original.jobLevelExperience?.label}
							</Text>
						</VStack>
						<HStack justify={"space-between"} spacing={2}>
							{!!row.original.reference && (
								<Text fontWeight={400} color={"grey"} m={0} bottom={0}>
									{row.original.reference}
								</Text>
							)}
							{!!row.original.period && (
								<Text fontWeight={400} color={"grey"} m={0} bottom={0}>
									{row.original.period?.label}
								</Text>
							)}
						</HStack>
					</VStack>
				),
			},
			{
				accessorKey: "description",
				header: "Description",
				cell: ({ row, getValue }) => (
					<VStack
						justify={"start"}
						align={"start"}
						spacing={4}
						minW={"200px"}
						display={{ base: "none", lg: "flex" }}
					>
						<Text fontWeight={500} color={textColor} noOfLines={2} mb={0}>
							{`Our client is looking for a ${row.original.title} ${
								row.original.jobLevelExperience?.label
									? `with a ${row.original.jobLevelExperience?.label} level of experience`
									: ""
							} ${
								row.original.location?.city
									? `, located in ${row.original.location?.city}`
									: ""
							}`}
						</Text>
						<HStack
							align={"center"}
							spacing={{ base: 8, lg: 16 }}
							w={"100%"}
							mb={2}
						>
							<Text fontWeight={400} color={"grey"} mb={0}>
								{row.original.location?.city}
							</Text>
							<Text fontWeight={400} color={"grey"} mb={0}>
								{row.original.jobLevelExperience?.label}
							</Text>
						</HStack>
					</VStack>
				),
				footer: (props) => props.column.id,
			},
			{
				accessorKey: "id",
				header: "Actions",
				enableSorting: false,
				cell: ({ row, getValue }) => (
					<>
						<Button
							variant={"link"}
							onClick={() => onJobOfferClick(getValue())}
							color={textColor}
							fontWeight={400}
							display={{ base: "none", lg: "flex" }}
							mx={4}
						>
							More info
						</Button>
						<IconButton
							aria-label={"Next"}
							icon={<ChevronRightIcon h={6} w={6} />}
							onClick={() => onJobOfferClick(getValue())}
							bg={"transparent"}
							color={textColor}
							fontWeight={400}
							display={{ base: "flex", lg: "none" }}
							mx={4}
						/>
					</>
				),
				footer: (props) => props.column.id,
			},
		];
		return headers;
	}, []);

	return (
		<>
			<Stack direction="column" px={{ base: 0, lg: 2 }}>
				<Sheet
					position={"initial"}
					zIndex={1}
					overflow={"visible"}
					p={0}
					boxShadow={"none"}
				>
					<VStack>
						<Box
							position={"relative"}
							w={"100%"}
							overflow={"hidden"}
							borderTopLeftRadius={5}
							borderTopRightRadius={5}
						>
							<Box
								h={{ base: "140px", lg: "200px" }}
								w={"100%"}
								style={{
									filter: "saturate(0.4)",
									background: `url(/banner.jpeg)`,
									backgroundSize: "cover",
									backgroundPositionY: "-340px",
								}}
							/>
							<VStack
								position={"absolute"}
								top={0}
								width={"100%"}
								height={"100%"}
								alignItems={"center"}
								justifyContent={"center"}
								backgroundColor={colors.brand["700"] + "CC"}
								p={4}
							>
								<Heading
									color={"white"}
									fontSize={{ base: "24px", lg: "32px" }}
								>
									Browse all our job offers here
								</Heading>
								<Text
									color={"white"}
									textAlign={"center"}
									fontSize={{ base: "20px", lg: "28px" }}
								>
									Apply or submit your spontaneous application
								</Text>
							</VStack>
						</Box>
					</VStack>
					<Stack
						direction={"column"}
						justify={"center"}
						align={"center"}
						p={2}
						backgroundColor={colors.brand["600"]}
						borderBottomLeftRadius={5}
						borderBottomRightRadius={5}
					>
						<Stack
							direction={"row"}
							justify={"start"}
							align={"center"}
							spacing={4}
							flex={23}
						>
							<JobOffersFiltersForCandidates
								persist
								persistKey={LSFiltersCandidateJobOffers}
								onSubmit={(values) => onFiltersSubmit(values)}
							/>
						</Stack>
						<Stack
							direction={{ base: "column", lg: "row" }}
							justify={"center"}
							align={"center"}
							mt={2}
						>
							<Heading
								size={"sm"}
								fontWeight={"normal"}
								m={0}
								color={"gray.300"}
							>
								Still haven't found what you're looking for? Leave us your CV
								and we'll contact you once we have a job offer that corresponds
								to your profile.
							</Heading>
							<Button
								variant={"link"}
								p={0}
								color={"gray.300"}
								onClick={() => router.push("/auth/register")}
							>
								Click here
							</Button>
						</Stack>
					</Stack>
				</Sheet>
				<Box>
					<MainButton onClick={toggleMap} mb={1} h={"30px"} borderRadius={8}>
						{isMapShown ? "Hide map" : "Show map"}
						{isMapShown ? <ChevronUpIcon ml={4} /> : <ChevronDownIcon ml={4} />}
					</MainButton>
				</Box>

				<Sheet
					h={isMapShown ? { base: "410px", lg: "410px" } : "0px"}
					w={"100%"}
					overflow={"hidden"}
					transition={"height 0.3s ease-in-out"}
					p={0}
					boxShadow={"none"}
				>
					{isMapImageShown && (
						<Box w={"100%"} h={"100%"}>
							<DynamicMap
								containerProps={{
									center: mapCenter,
									zoom: selectedMarker ? 14 : 3,
								}}
								mapMarkersProps={{
									markers: mappedMarkers,
									selectedMarker,
									onClick: (marker) => onJobOfferClick(marker.jobOffer?.id),
								}}
							/>
						</Box>
					)}
				</Sheet>
				<Sheet
					flex={2}
					maxH={"100%"}
					maxW={{ sm: "100vw", lg: "100%" }}
					transition={"max-width 0.5s ease-in-out"}
					p={0}
					boxShadow={"none"}
				>
					<Box
						maxH={"100%"}
						pb={4}
						px={0}
						overflow={{ base: "hidden", lg: "auto" }}
						ref={listRef}
					>
						<FDC
							isError={isError}
							isLoading={isLoading}
							errorMessage={errorMessage}
						>
							<Table
								data={data}
								columns={columns}
								onRowClick={(_, item) => onJobOfferClick(item.id as number)}
								paginationState={pagination}
								sortingState={sorting}
								onPageChange={setPagination}
								onSortingChange={setSorting}
								headersShown={false}
								labels={{
									emptyListLabel:
										"No job offers have been found for your criteria...",
								}}
								rowProps={{
									_hover: { boxShadow: "none" },
									outline: "thin solid gainsboro",
									outlineOffset: "-2px",
								}}
							/>
						</FDC>
					</Box>
				</Sheet>
			</Stack>
			<Drawer
				onClose={onJobOfferPreviewClose}
				isOpen={!!currentJobOffer}
				size={"xl"}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>
						<VStack>
							<HStack w={"85%"} justify={"space-between"}>
								<IconButton
									aria-label={"Previous"}
									icon={<ChevronLeftIcon h={6} w={6} />}
									disabled={!jobOfferIndex}
									onClick={previewPrevious}
								/>
								<HStack>
									<Heading
										fontSize={{ base: "20px", lg: "32px" }}
										textAlign={{ base: "center", lg: "start" }}
										fontWeight={600}
										color={textColor}
									>
										{currentJobOffer?.title}
									</Heading>
								</HStack>
								<IconButton
									aria-label={"Next"}
									icon={<ChevronRightIcon h={6} w={6} />}
									disabled={
										typeof jobOfferIndex === "number" &&
										jobOfferIndex >= data?.items?.length - 1
									}
									onClick={previewNext}
								/>
							</HStack>
							<HStack w={"100%"} justify={{ base: "center", lg: "end" }}>
								{!!currentJobOffer && (
									<ShareButton
										link={`${window?.location.origin}/?id=${currentJobOffer?.id}`}
									/>
								)}
								<ApplyForJobButton
									{...{
										canApply,
										onAuthorizedApply: onApplyClick,
										isLoading: createJobRequestQuery.isLoading,
									}}
								/>
							</HStack>
						</VStack>
					</DrawerHeader>
					<DrawerBody>
						<JobOfferFormDisplayForCandidates
							data={currentJobOffer}
							withTitle={false}
						/>
						<Box w={"100%"} h={"300px"}>
							<DynamicMap
								containerProps={{
									center: mapCenter,
									zoom: selectedMarker ? 14 : 5,
								}}
								mapMarkersProps={{
									markers: mappedMarkers,
									selectedMarker,
								}}
							/>
						</Box>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default JobOffersForCandidates;
