import React from "react";
import { Text as BaseText, TextProps } from "@chakra-ui/react";

export type IButton = TextProps & { text?: string; placeholder?: string };

export const TextWithPlaceholder: React.FC<IButton> = ({
	text,
	placeholder = "Not provided",
	...rest
}) => {
	return (
		<BaseText opacity={text ? 1 : 0.6} {...rest}>
			{text || placeholder}
		</BaseText>
	);
};

export default TextWithPlaceholder;
