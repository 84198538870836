import React, { FC, ReactNode, useEffect, useState } from "react";
import { AspectRatio, AspectRatioProps, Center, Text } from "@chakra-ui/react";
import { checkURL } from "@definitions/api";

interface PdfViewerProps extends AspectRatioProps {
	children?: ReactNode;
	fileUrl?: string;
	iframeProps?: React.ElementType<HTMLIFrameElement>;
}

const PdfViewer: FC<PdfViewerProps> = ({
	fileUrl,
	iframeProps,
	children,
	...rest
}) => {
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		fileUrl && prefetch(fileUrl);
	}, []);

	const prefetch = async (url: string) => {
		try {
			await checkURL(url);
		} catch (e) {
			setIsError(true);
			console.log(e);
		}
	};
	const onLoad = () => {
		console.log("loaded");
	};

	return (
		<Center w={"100%"}>
			{isError ? (
				<Text>
					An error occurred when loading this document. Please contact support.
				</Text>
			) : (
				<AspectRatio
					ratio={210 / 297}
					w={"100%"}
					maxW={"1000px"}
					mx={"auto"}
					{...rest}
				>
					<iframe
						width={"100%"}
						height={"100%"}
						src={fileUrl}
						onLoad={onLoad}
						onError={() => setIsError(true)}
						{...iframeProps}
					/>
				</AspectRatio>
			)}
		</Center>
	);
};

export default PdfViewer;
