import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
	Button,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Text,
	useColorModeValue,
	VStack,
	Wrap,
	WrapItem,
} from "@chakra-ui/react";
import FormAsyncSelect, {
	ICityOption,
	IOption,
} from "@components/form/form-async-select/form-async-select";
import selectKeys from "@definitions/select-keys";
import { getCities, getCountries } from "@api/geocoding.api";
import { IJobOfferFilters } from "@interfaces/api/job-offers.types";
import { MainButton } from "@components/buttons/main-button/main-button";
import { GrClose } from "react-icons/gr";
import { getUsers } from "@api/auth.api";
import useAuthContext from "@hooks/useAuthContext";
import { UserTypeEnum } from "@interfaces/api/auth.types";
import { readFromStorage, writeToStorage } from "@utils/local-storage";
import { LSFiltersJobOffers } from "@definitions/local-storage.keys";
import { BiSearch } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { chakraComponents, ChakraStylesConfig } from "chakra-react-select";
import { mapToLocations } from "@components/filters/mapToLocations";

interface JobOffersFiltersProps {
	onSubmit: (values: IJobOfferFilters) => void;
	initData?: FilterForm;
	persist?: boolean;
	persistKey?: string;
}

type FilterForm = IJobOfferFilters & {
	statusSelect?: IOption | null;
	countrySelect?: IOption[];
	citySelect?: ICityOption[];
	tagSelect?: IOption[];
	consultantSelect?: IOption[];
	[selectKeys.contractType]?: IOption[];
	[selectKeys.levelOfExperience]?: IOption[];
	[selectKeys.desk]?: IOption[];
};

const JobOffersFiltersForCandidates: React.FC<JobOffersFiltersProps> = ({
	onSubmit: onFormSubmit,
	persist,
	persistKey = LSFiltersJobOffers,
}) => {
	const { authData } = useAuthContext();
	const {
		jobEducationNeeds: jobEducationNeedsKey,
		contractType: contractTypeKey,
		levelOfExperience: levelOfExperienceKey,
		desk: deskKey,
	} = selectKeys;

	const {
		register,
		control,
		handleSubmit,
		reset,
		resetField,
		getValues,
		formState: { errors, isSubmitting },
		setValue,
		watch,
		setError,
		clearErrors,
	} = useForm<FilterForm>();

	const watchInput = watch("input");
	const watchCountry = watch("countrySelect");

	useEffect(() => {
		reset(readFromStorage(persistKey));
	}, [persist]);

	useEffect(() => {
		resetField("citySelect");
		resetField("cities");
	}, [watchCountry]);

	const resetValues = () => {
		reset({
			countrySelect: [],
			statusSelect: null,
			tagSelect: [],
			citySelect: [],
			[contractTypeKey]: [],
			[levelOfExperienceKey]: [],
			[deskKey]: [],
			consultantSelect: [],
			input: "",
		});
	};

	const onSubmit = async (values: FilterForm) => {
		const data: Partial<FilterForm> = {
			...values,
			locations: mapToLocations({
				cities: values.citySelect || [],
				countries: values.countrySelect || [],
			}),
			// countries: values.countrySelect?.map((item) => item.value.toString()) || [],
			statuses: values.statusSelect?.value.toString() || undefined,
			// cities: values.citySelect?.map((item) => item.label) || [],
			tags: values.tagSelect?.map((item) => item.value.toString()) || [],
			contractTypeIds:
				values[contractTypeKey]?.map((item) => Number(item.value)) || [],
			experienceIds:
				values[levelOfExperienceKey]?.map((item) => Number(item.value)) || [],
			deskIds: values[deskKey]?.map((item) => Number(item.value)) || [],
			consultantsIds:
				values.consultantSelect?.map((item) => Number(item.value)) || [],
		};
		if (persist) {
			writeToStorage(persistKey, data);
		}
		delete data.countrySelect;
		delete data.statusSelect;
		delete data.citySelect;
		delete data.consultantSelect;
		delete data.tagSelect;
		delete data[contractTypeKey];
		delete data[levelOfExperienceKey];
		delete data[deskKey];
		try {
			await onFormSubmit(data);
		} catch (e: any) {
			const { data } = e || {};
			Object.keys(data?.viewBag)?.forEach((key) =>
				setError(key as keyof IJobOfferFilters, data?.viewBag[key]),
			);
			console.log(e);
		}
	};

	const inputsBg = useColorModeValue("white", "gray.400");
	const inputsColor = useColorModeValue("gray.800", "gray.100");

	const selectStyles: ChakraStylesConfig = {
		container: (props) => ({
			...props,
			background: inputsBg,
			borderRadius: 10,
		}),
		placeholder: (props) => ({ ...props, fontWeight: 700, color: inputsColor }),
	};

	return (
		<form
			onSubmit={(e) => {
				clearErrors();
				handleSubmit(onSubmit)(e);
			}}
			style={{ width: "100%" }}
		>
			<Stack
				direction={{ base: "column", lg: "row" }}
				align={"center"}
				justify={"center"}
				w={"100%"}
				py={4}
			>
				<Stack direction={{ base: "column" }} align={"center"}>
					<Wrap
						align={"center"}
						justify={"center"}
						direction={{ base: "column", lg: "row" }}
					>
						<WrapItem>
							<Heading size={"md"} fontWeight={700} color={"white"}>
								Find a position:{" "}
							</Heading>
						</WrapItem>
						<WrapItem minW={"240px"}>
							<FormAsyncSelect
								name={"countrySelect"}
								placeholder={"Country"}
								control={control}
								isMulti
								selector={getCountries}
								mappingKeys={{
									labelKey: ["flag", "name.common"],
									valueKey: ["name.common"],
								}}
								styles={selectStyles}
							/>
						</WrapItem>
						{!!watchCountry?.length && (
							<WrapItem minW={"240px"}>
								<FormAsyncSelect
									name={"citySelect"}
									placeholder={"Cities"}
									control={control}
									selectorFilters={{
										Country: watchCountry?.map((country) => country.value),
									}}
									selector={getCities}
									mappingKeys={{
										labelKey: "label",
										valueKey: "value",
									}}
									isMulti
									styles={selectStyles}
									optionComponent={(props: any) => (
										<chakraComponents.Option {...props}>
											<VStack>
												<Text m={0} p={0} textAlign={"left"} w={"100%"}>
													{props.children}
												</Text>
												<Text
													fontSize={"12px"}
													color={"gray"}
													m={0}
													p={0}
													textAlign={"left"}
													w={"100%"}
												>
													{props.data.data?.country}
												</Text>
											</VStack>
										</chakraComponents.Option>
									)}
								/>
							</WrapItem>
						)}
						<WrapItem minW={"240px"}>
							<FormAsyncSelect
								name={levelOfExperienceKey}
								placeholder={"Level of experience"}
								control={control}
								selector={levelOfExperienceKey}
								isMulti
								styles={selectStyles}
							/>
						</WrapItem>
						<WrapItem minW={{ base: "240px", lg: "400px" }}>
							<InputGroup>
								<Input
									id={"input"}
									placeholder={"Search in titles and descriptions..."}
									{...register("input")}
									bg={inputsBg}
									fontWeight={700}
									color={inputsColor}
								/>
								<InputRightElement>
									{!!watchInput && (
										<Button
											onClick={() => {
												resetField("input");
												handleSubmit(onSubmit)();
											}}
											p={1}
											bg={"transparent"}
											_active={{ background: "transparent" }}
											_hover={{ background: "transparent" }}
										>
											<GrClose />
										</Button>
									)}
								</InputRightElement>
							</InputGroup>
						</WrapItem>
						<WrapItem>
							<Stack direction={"row"}>
								<MainButton
									type="submit"
									isLoading={isSubmitting}
									w={120}
									justifyContent={"start"}
									borderRadius={8}
									borderWidth={2}
									borderColor={"white"}
									_hover={{
										color: "gray.600",
										bg: "gray.200",
									}}
								>
									<BiSearch />
									<Text m={0} ml={2} lineHeight={"14px"}>
										Search
									</Text>
								</MainButton>
								<Button
									onClick={() => {
										resetValues();
										handleSubmit(onSubmit)();
									}}
									bg={"gray.500"}
									color={"white"}
									w={110}
									justifyContent={"start"}
									borderRadius={8}
									borderWidth={2}
									borderColor={"gray.200"}
									_hover={{
										color: "gray.600",
										bg: "gray.200",
									}}
								>
									<IoClose />
									<Text m={0} ml={2} lineHeight={"14px"}>
										Clear
									</Text>
								</Button>
							</Stack>
						</WrapItem>
					</Wrap>
				</Stack>
			</Stack>
		</form>
	);
};

export default JobOffersFiltersForCandidates;
